import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faThLarge } from "@fortawesome/free-solid-svg-icons"

const Paginator = ({ currentPathname }) => {
  const query = useStaticQuery(graphql`
    query MyQuery {
      allSitePage(
        filter: { path: { regex: "/projects/" } }
        sort: { fields: path, order: ASC }
      ) {
        edges {
          node {
            path
          }
        }
      }
    }
  `)

  const allProjectPages = query.allSitePage.edges

  const currentPath = currentPathname.replace(/\\|\//g, "")
  const cleanProjectPages = []
  allProjectPages.forEach(element =>
    cleanProjectPages.push(element.node.path.replace(/\\|\//g, ""))
  )

  let currentPageIndex = cleanProjectPages.findIndex(
    element => element === currentPath
  )

  const nextPageIndex =
    currentPageIndex < allProjectPages.length - 1 ? currentPageIndex + 1 : 0
  const previousPageIndex =
    currentPageIndex > 0 ? currentPageIndex - 1 : allProjectPages.length - 1

  return (
    <div className="d-flex align-items-baseline mt-3 pager">
      <div className="w-100 previous">
        <Link
          className="btn btn-outline-dark btn-sm"
          to={allProjectPages[previousPageIndex].node.path}
        >
          Previous
        </Link>
      </div>
      <div className="w-100 text-center">
        <Link className="btn btn-outline-dark btn-sm" to="/work">
          <FontAwesomeIcon icon={faThLarge} />
        </Link>
      </div>
      <div className="w-100 text-right next">
        <Link
          className="btn btn-outline-dark btn-sm"
          to={allProjectPages[nextPageIndex].node.path}
        >
          Next
        </Link>
      </div>
    </div>
  )
}

export default Paginator
