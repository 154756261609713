import React from "react"

import Container from "react-bootstrap/Container"
import { Row, Col } from "react-bootstrap"

import Carousel from "react-bootstrap/Carousel"
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed"
import SEO from "../../components/seo"
import Image from "../../components/image"
import Layout from "../../components/layout"
import Paginator from "../../components/paginator"

const BuildingAKnife = ({ location }) => (
  <Layout>
    <SEO title="Luk Vermeulen" />
    <Container>
      <Carousel className="mt-3">
        <Carousel.Item>
          <Image filename="building-a-knife/knife.jpg" alt="finished knife" />
        </Carousel.Item>
        <Carousel.Item>
          <Image
            filename="building-a-knife/knife-blank.jpg"
            alt="forged knife blank"
          />
          <Carousel.Caption className="d-none d-md-block">
            <p>The forged knife blank ground to shape.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image
            filename="building-a-knife/assembly.jpg"
            alt="Assembling the knife blank and the handle scales."
          />
          <Carousel.Caption className="d-none d-md-block">
            <p>Assembling the knife blank and the handle scales.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="row mt-3">
        <div className="col-md-4">
          <dl>
            <dt>Steel</dt>
            <dd>C75 High Carbon Steel</dd>
            <dt>Handle</dt>
            <dd>Walnut wood</dd>
            <dt>Year</dt>
            <dd>2019</dd>
          </dl>
        </div>
        <div className="col-md-8">
          <p>
            Building a knife starts with making the knife blank. First, a piece
            of steel with the properties suited to the purpose of the knife is
            selected. This piece is then forged to form the blade and the tang
            roughly fitting the form of the design. The form is further refined
            by filing and grinding. When the blank is finished and all the holes
            for the handle are drilled, the blade is heattreated by annealing,
            hardening and tempering the steel so it can withstand forces and
            hold an edge. The next step is to assemble the knife blank and the
            handle scales. The handle can than be shaped to fit. As the last
            step, the blade is sharpened to form a proper cutting edge.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Image
            filename="building-a-knife/forge.jpg"
            alt="burning blacksmith forge"
            noOpacity={true}
          />
        </div>
      </div>

      <Paginator currentPathname={location.pathname} />
    </Container>
  </Layout>
)

export default BuildingAKnife
